import React from 'react';
import './App.css';
import Header from './components/common/Header/Header';
import HeroSection from './components/pages/Home/Section/HeroSection/HeroSection';
import ExploreSection from './components/pages/Home/Section/ExploreSection/ExploreSection';
import TransactionSection from './components/pages/Home/Section/TransactionSection/TransactionSection';
import YVMSection from './components/pages/Home/Section/YVMSection/YVMSection';
import TeamSection from './components/pages/Home/Section/TeamSection/TeamSection';
import Footer from './components/common/Footer/Footer';
import MetaVerseSection from './components/pages/Home/Section/MetaVerseSection/MetaVerseSection';

function App() {
  return (
    <>
      <div><Header /></div>
      <div><HeroSection /></div>
      <div><ExploreSection /></div>
      <div id="about"><YVMSection /></div>
      <div><TransactionSection /></div>
      <div><MetaVerseSection /></div>
      <div id="team"><TeamSection /></div>
      <div><Footer /></div>
    </>
  );
}

export default App;
